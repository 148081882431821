<template>
  <div class="App">
    <div class="flex pa4-l pa2 w-100 h-100">
      <div class="self-center">
        <GaoYuChen />
      </div>
    </div>
  </div>
</template>

<script>
  import GaoYuChen from './GaoYuChen.vue';
  import Snap from 'snapsvg';

  export default {
    name: 'app',
    components: {
      GaoYuChen
    }
  };
</script>
